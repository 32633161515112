import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ToggleSwitch } from '../../elements';
import { LoyaltyBannerContainer } from '../loyalty/LoyaltyContainers';
import { Icon, IconType } from '../../elements/icons';
import { AppContext } from '../../context/AppContext';
import { UserContext } from '../../context/auth/UserContext';
import { calculateEuros } from '../checkout/functions/calculatePrices';
import { Popover } from '../../elements/popover';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { AuthContext } from '../../context/auth/AuthContext';
import { PopupType } from '../../context/popups.enum';

export const CreditBanner = ({setCreditApplied, creditUsed, amountMinusDiscountsAndCredit, askForLogin=false} : props) => {
    const {goToPopup} = usePopupNavigation();
    const { user: { creditDetails } } = useContext(UserContext);
    const { isLogged } = useContext(AuthContext);
    const {
        state: {
            restaurant: {
                fast_checkout
            },
            checkout: {
                creditCents,
                paymentMethods
            },
            wallet: {
                balanceCredit
            }
        },
    } = useContext(AppContext);
    const shouldAskForLogin = !isLogged && askForLogin
    const {t} = useTranslation();
    const euros = balanceCredit ? calculateEuros(balanceCredit) : null
    const remaining_euros_cents = balanceCredit as number - creditUsed
    const remaining_euros = calculateEuros(remaining_euros_cents)
    const disabled = amountMinusDiscountsAndCredit === 0 && creditUsed === 0 && !shouldAskForLogin
    const [openedPopover, setOpenPopover] = useState(false)
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));
    const restaurantCredits = creditDetails.filter((credit: any) => credit.restaurant_id === restaurantId);
    const isOnlyWallet = paymentMethods.length > 0 && paymentMethods.every(method => method === 'Wallet');
    const path = window.location.pathname
    const isEventsCatalog = path === '/event_catalog'
    
    useEffect(() => {
        isOnlyWallet && setCreditApplied(true)
    }, [isOnlyWallet])
    

    const DisabledDiscount = () => {
        return (
          <div style={{ fontSize: 12, padding: "0 10px 0 10px", display: "inline-block", whiteSpace: "nowrap"}}>
            <Trans i18nKey={"disabled_discount_msg"}>
              <b>It is not possible to apply this discount</b> because your bill is <strong>€0.00</strong>
            </Trans>
          </div>
        );
    };

    if (isEventsCatalog) return <></>

    if ((!restaurantCredits || restaurantCredits.length === 0) && !shouldAskForLogin) return <></>
    if (restaurantCredits && restaurantCredits.length > 0 && restaurantCredits[0].balance === 0 && !shouldAskForLogin) return <></> 
    
    if((restaurantCredits && restaurantCredits.length > 0) || shouldAskForLogin) return (
        <div>
            <LoyaltyBannerContainer disabled={disabled} switchType={`credit_${!!creditCents}`} onClick={() => disabled ? setOpenPopover(true) : shouldAskForLogin ?  goToPopup(PopupType.Register) : setCreditApplied(wasApplied => !wasApplied)}>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <Icon type={IconType.CreditBanner}/>
                    <p>
                        {shouldAskForLogin ?
                        t('use_wallet')
                        :
                        creditCents ? 
                            <Trans i18nKey={"{{euros}} applied"}>
                                Ready! 🎉 You have ${{remaining_euros}}
                            </Trans> 
                        :
                            t("apply {{euros}} of credits", { euros })
                        }           
                    </p>
                </div>
                {shouldAskForLogin ?  
                    <Icon type={IconType.Arrowforward} size={10}/>
                :
                    <ToggleSwitch switchType={`credit_${!!creditCents}`} isChecked={!!creditCents} disabled={disabled}/>
                }
            </LoyaltyBannerContainer>
            <Popover setOpened={setOpenPopover} isOpened={openedPopover}>
                <DisabledDiscount/>
           </Popover>
        </div>
    )

    return <></>
};

type props = {
    setCreditApplied: Dispatch<SetStateAction<boolean>>
    creditUsed: number
    amountMinusDiscountsAndCredit: number
    askForLogin?: boolean
}
