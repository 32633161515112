import React, { useContext } from 'react'
import { Icon, IconType } from '../icons'
import { PaymentLogos } from '../payment-logos'
import { FooterContainer } from './FooterContainers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { GB_FEATS } from '../../growthbook'
import { AppContext } from '../../context/AppContext'

type Props = {
  showPaymentLogos?: boolean;
}

export const FooterComponent = ({showPaymentLogos}: Props) => {
  const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);
  const { state: { restaurantCustomization } } = useContext(AppContext);

  return (
    <FooterContainer>
      {showPaymentLogos &&
        <div style={{background: restaurantCustomization.theme === 'red' ? '#FFF' : ''}}>
          <PaymentLogos/>
        </div>
      }
      {showYumminnLogo &&
        <div>
          Powered by
          <Icon type={IconType.Logo} size={16}/>
        </div>
      }
    </FooterContainer>
  )
}