import React, { useContext } from 'react'
import styled from "styled-components";
import { Icon, IconType } from '../icons';
import { AppContext } from '../../context/AppContext';

export const PaymentLogosComponent = () => {
  const { state: { checkout: { paymentMethods }, restaurantCustomization } } = useContext(AppContext);

  return (
    <PaymentLogosContainer>
      {paymentMethods.includes("Apple Pay") &&
        <Icon type={IconType.ApplePay} colorIcon={restaurantCustomization.theme === 'red' ? 'black' : ''}/>
      }
      {paymentMethods.includes("Gpay") &&
        <Icon type={IconType.GooglePay} colorIcon={restaurantCustomization.theme === 'red' ? 'black' : ''}/>
      }
      {paymentMethods.includes("Card") &&
        <>
          <Icon type={IconType.Visa}/>
          <Icon type={IconType.Master}/>
        </>
      }
      {paymentMethods.includes("Bizum") &&
        <Icon type={IconType.BizumColor}/>
      }
    </PaymentLogosContainer>
  )
}

const PaymentLogosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  filter: ${({theme}) => theme.payment_logos_filter};
`;