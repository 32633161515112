import { toast } from "react-toastify";
import { Coords, Payment, Receipt } from "../../../context/app";
import { getProgramsFromStorage } from "./getProgramsFromStorage";
import { t } from "i18next";
import { getTotalModsPrice } from "../../order-and-pay/logic/productFunctions";

export const calculateEuros = (cents: any) => {
    if (cents === undefined || cents === null) return "0";
    if (cents.toString().indexOf(".") !== -1){ //in case number is decimal
        cents = cents.toString().substring(0, cents.toString().indexOf(".")) //removing the dot
    }
    let centsStr = cents.toString();
    let length = centsStr.length;
    let minus = "";

    if(centsStr.startsWith("-")){
        length = length - 1;
        centsStr = centsStr.substr(1, length);
        minus = length > 2 ? "-" : "-"
    }

    const numberOfZerosToAdd = length > 2 ? 0 : 3 - length;
    centsStr = minus + "0".repeat(numberOfZerosToAdd) + centsStr;
    length = centsStr.length ;

    return formatNumber(centsStr.substr(0, length - 2) + "," + centsStr.substr(length - 2, 2));
}

function formatNumber(num: string) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export function removeUnusefulDecimals(input: string): string {
    // Match numbers with exactly two zeros after a comma or decimal point
    const match = input.match(/^(\d+)[.,]00$/);
    return match ? match[1] : input; // Return the integer part if valid, else return the original input
}

export const calculateCents = (price: any) => {
    if (price === "0" || price === ""){
        return price;
    }
    else if(price === undefined){
        return 0;
    }
    else {
        price = (price * 100).toFixed(2).toString()

        if (price.toString().indexOf(".") !== -1){ //in case number is decimal
            price = price.substring(0, price.indexOf(".")) //removing the dot
        }


        if (price[0] === "0"){
            price = "0".concat(price.substring(1, price.length))
        }
        

        else if (price.length <= 2){
            price = price.substring(0, price.length) 
            price = "0" + price
        }
        
        return price;
    }
}

export const calculatePoints = (cents: number) => {
    const {pointsProgram} = getProgramsFromStorage();

    if (!pointsProgram) {
        return 0;
    }

    return Math.floor(cents / 100);
};

export const calculateCashbackCents = (cents: number) => {
    const {loyaltyProgram} = getProgramsFromStorage();

    if (!loyaltyProgram) {
        return 0;
    }

    const minPayment = Number(sessionStorage.getItem('loyalty_earning_min_payment_cents') || 0);
    const earningPercentage = Number(sessionStorage.getItem('loyalty_earning_percentage') || 0);
    const cashbackCents = minPayment <= cents ? Math.floor(cents * earningPercentage / 100) : 0;

    return cashbackCents;
};

const reducePayment = (payment: Payment | Receipt, keys: Array<keyof Payment | keyof Receipt>) => {
    return Object.entries(payment).reduce((cents, [key, value]) => {
        if (keys.includes(key) && typeof value === 'number') {
            cents += value;
        }

        return cents;
    },0);
};

const calculateAllDiscountCents = (payment: Payment | Receipt | null) => {
    if (!payment) return 0;

    const DISCOUNT_CENTS_KEYS = [
        'loyalty_discount_cents',
        'general_discount_cents',
        'product_discount_cents',
        'zerosix_discount_cents',
        'bizum_promotion_cents',
        'master_c2p_promotion_cents',
        'code_coupon_cents',
        'credit_cents'
    ];

    return reducePayment(payment, DISCOUNT_CENTS_KEYS);
};

export const calculateTotal = (payment: Payment | Receipt | null) => {
    if (!payment) return 0;

    const PAY_CENTS_KEYS = [
        'base_amount_cents',
        'tip_cents',
        'delivery_fee_cents',
    ];

    const payCents = reducePayment(payment, PAY_CENTS_KEYS);
    const discountCents = calculateAllDiscountCents(payment);

    return Math.max(payCents - discountCents, 0);
};

export const customRound = (number: number) => {
    const rounded = Math.floor(number);
    const decimal = number - rounded;
    if (decimal > 0.5) {
        return rounded + 1;
    }
    // decimal is exactly 0.5 or less, round down
    return rounded;
};

const calculateDistanceMeters = ({lat: lat1, lng: lng1}: Coords, {lat: lat2, lng: lng2}: Coords) => {
    if (lat1 === null || lng1 === null || lat2 === null || lng2 === null) return NaN;

    // Haversine Distance Formula
    const EARTH_RADIUS_METERS = 6373000;
    
    const toRad = (n: number) => (n * Math.PI / 180);

    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1)

    const a = (Math.sin(dLat / 2) ** 2) + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * (Math.sin(dLng / 2) ** 2);
    const angularDistance = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceMeters = EARTH_RADIUS_METERS * angularDistance;

    return distanceMeters;
};

export const calculateDeliveryFee = (coords1: Coords | null, coords2: Coords | null, FeeCentsPerKm: number, minFeeCents: number) => {
    if (!coords1 || !coords2) {
        toast.error(t('calculate_delivery_fee_error'));
        return NaN;
    }

    if (!FeeCentsPerKm) return Math.max(minFeeCents, 0);

    const fliying_distance_km = calculateDistanceMeters(coords1, coords2) / 1000;

    if (Number.isNaN(fliying_distance_km) || fliying_distance_km < 0) {
        toast.error(t('calculate_delivery_fee_error'));
        return NaN;
    }

    return customRound(Math.max(fliying_distance_km * FeeCentsPerKm, minFeeCents));
};

export const calculateOrderTotal = (receipt: Receipt | Payment) => {
    if (!receipt.order_items || !receipt.order_items.length) return 0;

    return receipt.order_items.reduce((total, product) => {
        const modPrice = getTotalModsPrice(product.modifiers);

        total += (product.base_unit_price_cents + modPrice) * product.amount;

        return total;
    }, 0);
};

export const formatHour = (startMinute: number | null | undefined): string => {
    if (startMinute !== null && startMinute !== undefined) {
        const startHour = Math.floor(startMinute / 60);
        const startPart = `${startHour.toString().padStart(2, '0')}:${(startMinute % 60).toString().padStart(2, '0')}`;
        return startPart;
    }
    return "-";
};

export const parseDecimalString = (str: string) => {
    let value = str.replaceAll(/[^\d.]/g, '');

    if (value.includes('.')) {
        const index = value.indexOf('.');
        value = value.slice(0, index + 3);

        if (value.startsWith('.')) {
            value = '0' + value;
        }
    }

    return value;
};