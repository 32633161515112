import styled from "styled-components";

export const BadgeContainer = styled.div<BadgeProps>`
  font-size: ${({theme}) => theme.font_sm};
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  color: ${({theme, textColor}) => textColor ? textColor : theme.text_light};
  background-color: ${({color}) => color};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  border-radius: 999px;
  padding: 6px 8px;
  box-shadow: ${({theme, shadow}) => !shadow ? 'none' : theme.shadow_sm};
  position: relative;

  p {
    font-size: ${({theme}) => theme.font_xs};
    margin: 0;
  }
`;

export const BlackText = styled.span`
  color: ${({theme}) => theme.text_dark};
`;

type BadgeProps = {
  color: string;
  textColor?: string;
  shadow?: boolean;
};