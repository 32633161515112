import React, { createContext, useCallback, useContext, useReducer } from "react";
import { CartEventTicket, EventCatalogContext as EventCatalogContextType } from "./app";
import { eventCatalogReducer } from "./eventCatalogReducer";
import {  } from "./auth/AuthContext";
import { PopupType } from "./popups.enum";
import { usePopupNavigation } from "../shared/utils/usePopupNavigation";
import { UserContext } from "./auth/UserContext";

const initialState: EventCatalogContextType = {
  state: {
    tickets: [],
    totalPriceCents: 0,
    totalAmount: 0,
  },
  addTicket: () => null,
  removeTicket: () => null,
  clearTickets: () => null,
};

export const EventCatalogContext = createContext(initialState);

export const EventCatalogContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(eventCatalogReducer, initialState.state);
  
  const addTicket = useCallback((ticket: CartEventTicket) => {
    const newTickets = [...state.tickets];
    const ticketIndex = newTickets.findIndex(item => item.id === ticket.id);
    ticketIndex !== -1 ? newTickets[ticketIndex].amount += ticket.amount : newTickets.push(ticket);
    
    dispatch({type: 'SET_TICKETS', payload: newTickets});
  }, [state.tickets]);
  
  const removeTicket = useCallback((id: number) => {
    const newTickets = [...state.tickets];
    const ticketIndex = newTickets.findIndex(item => item.id === id);
    
    if (ticketIndex !== -1) {
      newTickets[ticketIndex].amount > 1 ? newTickets[ticketIndex].amount-- : newTickets.splice(ticketIndex, 1);
    }

    dispatch({type: 'SET_TICKETS', payload: newTickets});
  }, [state.tickets]);

  const clearTickets = () => dispatch({type: 'CLEAR_TICKETS'});

  return (
    <EventCatalogContext.Provider value={{ state, addTicket, removeTicket, clearTickets }}>
      {children}
    </EventCatalogContext.Provider>
  );
};