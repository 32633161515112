import { t } from 'i18next';
import React, { useContext } from 'react';
import { ListContainer, ListItem, ProductList, SplitList } from './ProductDetailsListContainers';
import { Icon, IconType } from '../icons';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { EventPurchaseTicket, Payment, Receipt, EventTicketPurchaseWithAmount } from '../../context/app';
import { ModifiersList } from '../../pages/order-and-pay/product/ModifiersList';
import { DashedSeparator } from '../../pages/payment-successful/PaymentSuccessfulContainers';
import { AppContext } from '../../context/AppContext';

type Props = {
  receipt: Receipt | Payment
  tickets: EventPurchaseTicket[] | null
};

export const ProductDetailsListComponent = ({receipt, tickets}: Props) => {
  const {
    state: {
      checkout: {
        totalBill,
      }
    }
  } = useContext(AppContext);

  type TicketCountsResult = {
    counts: Record<number, EventTicketPurchaseWithAmount>;
    totalAmount: number;
  };

  const calculateTicketCounts = (tickets: EventPurchaseTicket[]|null) : TicketCountsResult => {
    if (!tickets || tickets.length === 0) {
      return { counts: {}, totalAmount: 0 }; 
    }
    let totalAmount = 0;

    const counts = tickets.reduce<Record<number, EventTicketPurchaseWithAmount>>((acc, ticket) => {
      const id = ticket.event_ticket.id;

      console.log({acc})

      if (!acc[id]) {
        acc[id] = { ...ticket, amount: 0 };

        if (!acc[id].event_ticket.amount) {
          acc[id].event_ticket.amount = 0;
        }
      }

      acc[id].amount += 1;

      acc[id].event_ticket.amount = acc[id].amount;
  
      totalAmount += 1;

      return acc;
    }, {});

    return { counts, totalAmount };
  };
  
  const { counts: ticketCounts, totalAmount } = calculateTicketCounts(tickets);
  const uniqueTickets = Object.values(ticketCounts);

  if (receipt.order_items?.length || (tickets && tickets.length > 0)) return (
    <>
      <ListContainer>
        <h4>
          <span>
            {totalAmount > 0 ? totalAmount : (receipt.order_items?.length || 0) + (receipt.free_products?.length || 0)}
          </span>
          {tickets && tickets.length > 0 ? t('tickets') : t('products')}
        </h4>
        <ProductList>
          {receipt.order_items?.map(product => (
            <React.Fragment key={product.id}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b>{calculateEuros(product.base_unit_price_cents) + '€'}</b>
              </ListItem>
              <ModifiersList modifiers={product.modifiers} receipt/>
            </React.Fragment>
          ))}
          {receipt.free_products?.map(product => (
            <React.Fragment key={product.id}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b className='gift'>{t('gift')}</b>
              </ListItem>
              <ModifiersList modifiers={product.modifiers} receipt/>
            </React.Fragment>
          ))}
          {uniqueTickets && uniqueTickets.length > 0 && uniqueTickets.map((ticket) => (
            <React.Fragment key={ticket.code}>
              <ListItem>
                <span>{ticket.amount}</span>
                <h5>{ticket.event.name}</h5>
              </ListItem>
              <ModifiersList ticket={ticket.event_ticket} receipt/>
            </React.Fragment>
          ))}
        </ProductList>
      </ListContainer>
      <DashedSeparator/>
    </>
  );

  if (receipt.items?.length) return (
    <>
      <ListContainer>
        <h4>
          <span>
            {(receipt.items?.length || 0) + (receipt.free_products?.length || 0)}
          </span>
          {t('products')}
        </h4>
        <ProductList>
          {receipt.items?.map(product => (
            <React.Fragment key={product.key}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b>{product.price + '€'}</b>
              </ListItem>
              <RecursiveList modifiers={product.modifiers}/>
            </React.Fragment>
          ))}
          {receipt.free_products?.map(product => (
            <React.Fragment key={product.id}>
              <ListItem>
                <span>{product.amount}</span>
                <h5>{product.name}</h5>
                <b className='gift'>{t('gift')}</b>
              </ListItem>
              <ModifiersList modifiers={product.modifiers} receipt/>
            </React.Fragment>
          ))}
        </ProductList>
      </ListContainer>
      <DashedSeparator/>
    </>
  );

  if (['amount', 'evenly'].includes(receipt.split_type || '')) return (
    <>
      <SplitList>
        <h4>{t('split_' + receipt.split_type)}</h4>
        <p>
          {t('table_total')}
          <b>{calculateEuros(totalBill) + '€'}</b>
        </p>
        <p>
          {t('subtotal')}
          <b>{calculateEuros(receipt.base_amount_cents) + '€'}</b>
        </p>
      </SplitList>
      <DashedSeparator/>
    </>
  );

  return (
    <div>
      <p>{t('subtotal')}</p>
      <p>{calculateEuros(receipt.base_amount_cents) + '€'}</p>
    </div>
  );
};

type Mods = {
  modifiers: {
    name: string
    amount: number
    total_price: number
  }[]
};

const RecursiveList = ({modifiers}: Mods) => {
  if (!modifiers || !modifiers.length) return <></>;

  return (
    <ProductList>
      {modifiers.map((mod, index) => (
        <React.Fragment key={'modifier-' + index + mod.name}>
          <ListItem>
            <Icon type={IconType.TabArrow} size={10}/>
              <span>{mod.amount}</span>
              <h5>{mod.name}</h5>
              {!!mod.total_price &&
                <span>{calculateEuros(mod.total_price) + '€'}</span>
              }
          </ListItem>
        </React.Fragment>
      ))}
    </ProductList>
  );
};