import React, { useState } from 'react'
import styled from 'styled-components';
import { LoginForm, RegisterForm } from '../../forms/register'
import { useFeatureValue } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';
import { SquareIcon } from '../square-icon';
import { IconType } from '../icons';
import { useTranslation } from 'react-i18next';
import { DEFAULT_REGISTER_TITLES } from '../../growthbook/defaults';

type Props = {
  from: string
  defaultAction?: 'register' | 'login'
  showFormByDefault?: boolean
  showTitle?: boolean
  giftedCreditAmount?: number
  defaultValues?: Partial<{
    email: string
    password: string
    confirm_password: string
    lopd: boolean
    news: boolean
    from: string
  }>
};

export const RegisterOrLogin = ({from, defaultAction = 'register', showFormByDefault = false, defaultValues, giftedCreditAmount, showTitle = true}: Props) => {
  const { t } = useTranslation();
  
  const titles = useFeatureValue(GB_FEATS.REGISTER_TITLES, DEFAULT_REGISTER_TITLES);
  
  const [action, setAction] = useState<'register' | 'login'>(defaultAction);

  return (
    <Container>
      {showTitle &&
        <>
          <SquareIcon type={IconType.User} fill='currentColor'/>
          {titles[`${action}_title`] &&
            <h4>{t(titles[`${action}_title`])}</h4>
          }
          {titles[`${action}_subtitle`] &&
            <p>{t(titles[`${action}_subtitle`])}</p>
          }
        </>
      }
      {action === 'register' &&
        <RegisterForm
          from={from}
          showFormByDefault={showFormByDefault}
          defaultValues={defaultValues}
          handleLinkClick={() => setAction('login')}
        />
      }
      {action === 'login' &&
        <LoginForm
          from={from}
          showFormByDefault={showFormByDefault}
          defaultValues={defaultValues}
          handleLinkClick={() => setAction('register')}
        />
      }
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  color: ${({theme}) => theme.text};
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  & > h4 {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    text-wrap: balance;
    margin: 0;
  }
  
  & > p {
    font-size: ${({theme}) => theme.font_md};
    line-height: 1.3;
    text-align: center;
    text-wrap: balance;
    margin: 0;
  }
`;