import styled, { css } from "styled-components";
import { BaseCard } from "../../cards";

const notification = (notify: boolean) => css`
  position: relative;

  &::after {
    content: '';
    display: ${notify ? '' : 'none'};
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: ${({theme}) => theme.notification};
    border-radius: ${({theme}) => theme.radius_round};
    height: 10px;
    width: 10px;
    z-index: 2;

    animation: notification 0.5s ease-in-out infinite alternate;
  }

  @keyframes notification {
    to { scale: 1.4 }
  }
`;

export const ReceiptButton = styled.button<{ notify: boolean }>`
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  color: currentColor;

  &:focus {
    outline: none;
  }

  ${({notify}) => notification(notify)}
`;

export const ReceiptListContainer = styled.div<Ticket>`
  position: fixed;
  top: 43px;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${({theme, isShown}) => isShown ? theme.backdrop_color : 'transparent'};
  transition: background-color 0.2s ease-in-out;
  pointer-events: ${({isShown}) => isShown ? 'all' : 'none'};
  
  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    background-color: ${({theme}) => theme.background};
    border-radius: ${({theme}) => `0 0 ${theme.radius_xl} ${theme.radius_xl}`};
    padding: 16px 24px 24px;
    box-sizing: border-box;
    translate: ${({isShown}) => isShown ? '0' : '0 -101%'};
    transition: translate 0.2s ease-in-out;
  }
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0;
  margin: 4px;
`;

export const ListItem = styled(BaseCard).attrs({as: 'li'})<Item>`
  position: relative;
  padding: 8px;
  min-height: 62px;
  display: flex;
  flex-direction: column;
  
  & > div {
    display: flex;
    gap: 8px;
    flex: 1;
    opacity: ${({isHistory}) => isHistory ? 0.7 : 1};

    .receipt_icon {
      display: flex;
      justify-content: center;
      width: 32px;
      position: relative;

      span {
        font-size: ${({theme}) => theme.font_xl};
      }

      svg {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        border-radius: ${({theme}) => theme.radius_round};
        background-color: ${({theme}) => theme.yumminn};
        color: ${({theme}) => theme.background};
        padding: 2px;
        border: 8px solid ${({theme}) => theme.yumminn}40;
        background-clip: padding-box;
      }

      span + svg {
        position: absolute;
        top: -8px;
        right: -12px;
        scale: 0.6;
      }
    }

    .order {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2px;
    }

    p {
      font-size: ${({theme}) => theme.font_xs};
      color: ${({theme}) => theme.text_a80};
      margin: 0;
      
      &:first-of-type {
        font-size: ${({theme}) => theme.font_md};
        color: ${({theme}) => theme.text};
      }
    }

    b {
      align-self: ${({isHistory}) => isHistory ? 'center' : 'flex-start'};
      font-weight: 600;
    }
    
    & > b {
      background-color: ${({theme, isHistory}) => isHistory ? theme.text_a10 : 'transparent'};
      padding: ${({isHistory}) => isHistory ? '2px 8px' : '0'};
      margin-left: auto;
      border-radius: ${({theme}) => theme.radius_round};
    }
  }
  
  & > p {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 600;
    line-height: 1.3;
    margin: 8px 0 0 40px;
    z-index: 1;
  }
  
  & > span {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: ${({theme}) => theme.font_xs};
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.3px;
    white-space: nowrap;
    color: ${({theme}) => theme.text_dark};
    padding: 4px 8px;
    border-radius: 4px;
    width: min-content;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  
  &:not(:has(> p)) div {
    .receipt_icon, & > svg {
      align-self: center;
    }
  }

  ${({notify}) => notification(notify)}
`;

export const NoReceipts = styled.span`
  white-space: nowrap;
  color: ${({theme}) => theme.text};
`;

const ticketStyles = (isShown: boolean) => css`
  position: fixed;
  top: ${isShown ? '0' : '-100%'};
  left: 0;
  width: calc(100% - 32px);
  border-radius: ${({theme}) => `0 0 ${theme.radius_lg} ${theme.radius_lg}`};
  margin: 0 16px;
  opacity: ${isShown ? '1' : '0'};
  max-height: calc(var(--dvh) - 16px);
  max-height: calc(100dvh - 16px);
  transition: all 0.4s ease-in-out;
  z-index: 9999;
  box-shadow: ${({theme}) => theme.shadow_overlay};
  overflow-y: auto;
`;

export const TicketContainer = styled.div<Ticket>`
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.background};

  ${({fullPage, isShown}) => !fullPage && ticketStyles(isShown)}
  
  header {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    background-color: ${({theme}) => theme.ticket_header_bg};
    padding: 24px 24px 16px;
    border-bottom: 1px solid ${({theme}) => theme.text_a10};

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      flex: 1;
    }

    & > button {
      color: currentColor;
      background-color: transparent;
      border: none;
      height: min-content;
      display: flex;
      padding: 16px;
      margin: -16px;
    }

    p:first-letter {
      text-transform: capitalize;
    }

    span {
      font-size: ${({theme}) => theme.font_xs};
      line-height: 1;
      color: ${({theme}) => theme.text_a80};
      display: inline-block; // :first-letter doesn't work with inline elements

      &::first-letter {
        text-transform: capitalize;
      }
    }

    .ready_at {
      display: flex;
      gap: 4px;
      font-size: ${({theme}) => theme.font_md};
      line-height: 1;
      margin-top: 8px;
      z-index: 1;
      
      b {
        font-weight: 600;
        white-space: nowrap;
      }
    }

    .status {
      span:first-of-type {
        font-size: ${({theme}) => theme.font_lg};
      }

      span:last-of-type {
        color: ${({theme}) => theme.text_dark};
        font-size: ${({theme}) => theme.font_md};
        font-weight: 500;
        border-radius: ${({theme}) => theme.radius_xs};
        padding: 4px;
      }
    }

    .locker {
      span {
        font-size: ${({theme}) => theme.font_md};
        font-weight: 500;
  
        &.name {
          color: ${({theme}) => theme.text_dark};
          border-radius: ${({theme}) => theme.radius_xs};
          padding: 4px;
        }

        &.key {
          color: ${({theme}) => theme.text};
          margin-left: 8px;
        }
      }
    }
  }
  
  h3 {
    display: flex;
    gap: 4px;
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    line-height: 1em;
    margin: 0;
  }
  
  img {
    width: 80px;
    height: 56px;
    object-fit: contain;
    background-color: ${({theme}) => theme.secondary};
    border-radius: ${({theme}) => theme.radius_sm};
  }

  p {
    margin: 0;
  }
  
  & > p {
    font-size: ${({theme}) => theme.font_sm};
    line-height: 1em;
    text-align: center;
  }

  b {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ReceiptDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px 24px;
  
  & > div {
    display: flex;
    justify-content: space-between;

    p::first-letter {
      text-transform: uppercase;
    }

    p:last-child {
      font-weight: 500;
    }

  }

  .address {
    flex-direction: column;
    background-color: ${({theme}) => theme.ticket_header_bg};
    border-radius: ${({theme}) => theme.radius_sm};
    padding: 8px;

    b {
      font-weight: 600;
    }

    button {
      align-self: start;
    }
  }

  .discount {
    color: ${({theme}) => theme.greenish};
  }

  .total {
    display: flex;
    align-items: center;
  }
`;

export const ClientCreditBox = styled.span` 
  background-color: ${({theme}) => theme.background_credits_tickets};  
  border-radius: 6px;
  font-weight: 400;
  justify-content: center;
  padding: 5px 15px;
  font-size: 14px;

  span {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: 5px;
    justify-content: center;
  }

  & > span {
    color: #${({theme}) => theme.text_credits_tickets};  
  }

  b {
    gap: 5px;
  }
`;

export const LoyaltyContainers = styled.div`
  background-color: ${({theme}) => theme.gray_20};
  border-radius: ${({theme}) => theme.radius_sm};
  padding: 8px;

  p {
    font-weight: 500;
  }
`;

export const OrderCodeContainer = styled.div`
  display: flex;
  gap: 4px;
  align-self: flex-start;
  background-color: ${({theme}) => theme.tertiary_a20};
  border-radius: ${({theme}) => theme.radius_xs};
  font-size: ${({theme}) => theme.font_sm};
  line-height: 1em;
  padding: 4px;
  
  b {
    font-weight: 700;
  }
`;

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--text);

  button {
    display: flex;
    background-color: transparent;
    border: none;
    color: currentColor;
    padding: 12px 16px;
    font-size: var(--font_md);
    font-weight: 600;
    font-family: var(--typography);
    text-decoration: underline;
  }

  button:last-of-type {
    margin-left: auto;
  }
  
  b {
    font-weight: 600;
  }
`;

type Ticket = {
  isShown: boolean
  fullPage?: boolean
};

type Item = {
  notify: boolean
  isHistory: boolean
};