import React, { useContext, useRef, useState } from "react";
import { CartCardContainer } from "./CardContainers";
import { eventTrack } from "../../../useGaTracker";
import { calculateEuros, removeUnusefulDecimals } from "../../pages/checkout/functions/calculatePrices";
import { Icon, IconType } from "../icons";
import { DashedSeparator } from "../../pages/payment-successful/PaymentSuccessfulContainers";
import { Counter } from "../counter";
import { SmallButton } from "../buttons";
import { t } from "i18next";
import { Badge } from "../badge";
import { useTheme } from "styled-components";
import { AppContext } from "../../context/AppContext";

type Props = {
  title: string
  priceCents: number
  discountCents?: number
  amount: number
  gifted_credit_amount?: number
  stock?: number
  tracker: string
  children?: React.ReactNode
  onAdd: () => void
  onSubtract: () => void
  edit?: () => void
};

export const CartCard = ({title, priceCents, discountCents, amount, gifted_credit_amount, stock, tracker, children, onAdd, onSubtract, edit}: Props) => {
  const [maxHeight, setMaxHeight] = useState(0)

  const detailsRef = useRef<HTMLDivElement>(null)
  const theme: any = useTheme();
  const { state: { restaurantCustomization } } = useContext(AppContext);


  const toggleExpanded = () => {
    !maxHeight && eventTrack(tracker);
    setMaxHeight(prevHeight => prevHeight ? 0 : (detailsRef.current?.clientHeight || 0));
  };

  return (
    <CartCardContainer isCollapsed={!maxHeight}>
      <header onClick={toggleExpanded}>
        <span>{amount}</span>
        <h3>{title}</h3>
        <i>{discountCents ? calculateEuros(priceCents) + '€' : ''}</i>
        <b style={{position: 'relative'}}>   
          {calculateEuros(priceCents - (discountCents ?? 0)) + '€'}
          {Boolean(gifted_credit_amount) && 
            <Badge className='creditCashback' color={theme.text_credits_tickets}>
              <Icon type={restaurantCustomization.theme === 'red' ? IconType.CreditCashbackRed : IconType.CreditCashback} size={12} />
              +{removeUnusefulDecimals(calculateEuros(gifted_credit_amount as number * amount))}€
            </Badge>
          }   
        </b>
        <Icon type={IconType.ArrowDown}/>
      </header>
      <div className='collapsable' style={{maxHeight}}>
        <div ref={detailsRef}>
          <DashedSeparator/>
          {children}
          <footer>
            {!edit ?
              <div/> /* placeholder */
            :
              <SmallButton onClick={edit}>
                <Icon type={IconType.Edit} size={16}/>
                {t('edit')}
              </SmallButton>
            }
            <button className='handler' onClick={toggleExpanded}/>
            <Counter max={stock} count={amount} onAdd={onAdd} onSubtract={onSubtract}/>
          </footer>
        </div>
      </div>
    </CartCardContainer>
  );
};