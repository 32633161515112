import React, { useContext, useEffect} from 'react';
import { toast } from "react-toastify";
import endpoints from "../endpoints/Endpoints.api";
import Manager from "../manager/Manager.api";
import ManagerAuth from "../manager/ManagerAuth.api";
import { t } from "i18next";
import useSWR from "swr";
import { AxiosResponse } from "axios";
import { postError } from '../../shared/utils/postError';


const login = (
    email:string,
    password:string,
    paymentId:string,
    restaurantId:string
) => Manager.client().post(endpoints.auth.login, { email, password, paymentId, restaurantId, origin: 'webapp' });

const validateToken = async (token: string, type: string) => {
    try {
        const response: any = await ManagerAuth.client(token, type).get(endpoints.auth.validate);
        if (response?.data.client_id)  {
            localStorage.setItem("client_id", response?.data.client_id);
        }
        
        return response.status === 200;
    } catch (error: any) {
        postError(error);
        console.error(error);
        return false;
    }
};

const sendVerificationMail = async (token_param?: string, type_param?: string) => {
    try {
        const token = token_param ? token_param : localStorage.getItem("token_yumminn");
        const type = type_param ? type_param : localStorage.getItem("type");

        if (!token || !type) throw new Error('Missing credentials');

        const response = await ManagerAuth.client(token, type).get(endpoints.auth.sendVerificationMail);
        
        if (!response || response.status !== 200) throw new Error(response.toString())

        toast.success(t('verification_email_sent'));
    } catch (error: any) {
        postError(error);
        console.error(error);
        toast.error(t('error_sending_verification_mail'));
    }
};

const verifyAccount = async (uuid: string) => {
    try {
        const response = await Manager.client().post(endpoints.auth.verifyAccount, { uuid });
        return response.status
    } catch (error: any) {
        postError(error);
        const status = Number((error as Error).message.match(/\d{3}$/)?.[0]);
        return status
    }
};

const useCheckVerification = () => {
    const token = localStorage.getItem("token_yumminn");
    const type = localStorage.getItem("type");

    const endpoint =
    token && type ? endpoints.auth.IsAccountVerified
    : null;
    
    const { data } = useSWR(
        endpoint,
        url => ManagerAuth.client(token, type).get(url) as Promise<AxiosResponse<{ is_verified : boolean}>>,
        {
            revalidateOnMount: false,
            refreshInterval: 10000,
        },
    );
    
    return data?.data?.is_verified || false;
}

const isUserVerified = (token: any, type: any, isVerifiedCallback: any, isNotVerifiedCallback: any) => {
    ManagerAuth.client(token, type).get(endpoints.auth.IsAccountVerified).then((response: any) => {
        if (response?.data?.is_verified) {
            isVerifiedCallback();
        } else {
            isNotVerifiedCallback();
        }
    }).catch(err => {
        postError(err);
        console.log(`IsAccountVerified catch: ${err.toString()}`);
        isNotVerifiedCallback();
    });
};

const useUserVerification = (isLogged: boolean) => {

    const token = localStorage.getItem("token_yumminn");
    const type = localStorage.getItem("type");
    const endpoint = isLogged ? endpoints.auth.IsAccountVerified : null;
    const { data, error } = useSWR(
        endpoint,
        url => ManagerAuth.client(token, type).get(url) as Promise<AxiosResponse<{ is_verified : boolean}>>,
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    const loading: boolean = !error && !data && Boolean(token) && Boolean(type);

    return {
        isVerified: data?.data?.is_verified || false,
        error,
        loadingVerification: loading,
    };
}

const deleteClient = async () => {
    try {
        const token = localStorage.getItem("token_yumminn");
        const type = localStorage.getItem("type");

        return await ManagerAuth.client(token, type).delete(endpoints.auth.deleteClient);
    } catch (error) {
        console.log("🚀 ~ deleteClient ~ error:", error);
        postError(error as Error);
    }
};
const postFcmToken = async (token: string, type: string) => {
    try {
        const fcmToken = localStorage.getItem('fcmToken');
        //const fcmToken = 'asdsadsad';
        if (fcmToken) {
            let deviceId = localStorage.getItem('deviceId');
            if (!deviceId) {
                deviceId = localStorage.getItem('androidDeviceId');
            }
            //const os = 'ios';
            let os = 'android';
            const storedOs = localStorage.getItem('os');
            if (storedOs) {
                os = storedOs;
            }
            const body = {
                app: 'greenvita',
                token: fcmToken,
                'device_id': deviceId,
                os
            };
            ManagerAuth.client(token, type).post(`${endpoints.auth.fcmToken}`, body)
        }
    } catch(e: any) {
        postError(e)
        console.log(`postFcmToken exception: ${e.toString()}`);
    }
}

export const Request = {
    login,
    validateToken,
    sendVerificationMail,
    verifyAccount,
    useCheckVerification,
    useUserVerification,
    isUserVerified,
    deleteClient,
    postFcmToken
};
