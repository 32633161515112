import styled, { css, keyframes } from "styled-components";
import React from 'react';
import { BaseCard, BottomSheet } from "../../../elements";

const ReversedColumn = styled.div`
    display: flex;
    flex-direction: column-reverse;
    isolation: isolate;
`;

const Card = styled(BaseCard)`
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 10px;
    position: relative;
    height: ${({isExpanded}) => isExpanded ? "auto": "94px"};
    
    img {
        height: 72px;
        width: 72px;
        border-radius: ${({theme}) => theme.radius_sm};
        object-fit: cover;
    }

    .discount {
        position: absolute;
        top: -8px;
        left: -8px;
    }

    img + .discount {
        left: -8px;
    }

    &:not(:has(img)) {
        padding: 10px 10px 10px 16px;
    }

`;

const MiniCard = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 140px;
  height: 225px;
  padding: 10px;
  position: relative;

  img {
    height: 100%;
    width: 120px;
    border-radius: ${({theme}) => theme.radius_xs};
    object-fit: cover;
  }
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
    line-height: 17px;

    span {
        font-size: 0.9rem;
        font-weight: 600;
    }

    h5 {
    font-size: 0.9rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    }
`;

const AddButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 13px;
    height: 13px;
    right: 7px;
    gap: 6px;
    justify-content: center;
    align-items: center;
`;
const PlusMinusIcon = styled.div`
    width: 13px;
    background-color: #404040;
    height: 13px;
    border-radius: 9999px;
    color: white;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CardDetailsContainer = styled.div<CardDetailsContainerProps>`
    display: ${({isExpanded}) => isExpanded ? 'unset' : 'grid'};
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    gap: 2px;
    height: ${({isExpanded}) => isExpanded ? 'auto' : '72px'};
    flex: auto;
    width: 0;
`;

const CardCounterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2px;
    padding-top: 4px;
    box-sizing: border-box;

    .out_of_stock {
        color: ${({theme}) => theme.danger};
    }
`;

const CartCardInnerContainer = styled.div`
    .discount {
        position: absolute;
        top: -8px;
        right: -8px;
    }

    img + .discount {
        left: -8px;
    }
`;

export const ProductPopupContainer = styled(BottomSheet)`
    display: flex;
    flex-direction: column;
    
    & > header {
        position: sticky;
        top: 0;
        margin-bottom: -40px;
        z-index: 2;
    }
    
    & > img {
        width: 100%;
        height: 100vw;
        object-fit: cover;
        flex-shrink: 0;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px;
        
        & > h2 {
            position: sticky;
            top: 0;
            font-size: ${({theme}) => theme.font_xl};
            font-weight: 600;
            line-height: 1;
            background: ${({theme}) => theme.background};
            width: 100%;
            margin: -16px 0;
            padding: 20px 0 16px;
            z-index: 1;
        }

        .item_tab_selector label{
            flex: 1;
        }

        & > p {
            font-size: ${({theme}) => theme.font_sm};
        }

        & > ul {
            margin-bottom: 8px;
        }

        & > hr {
            border-color: ${({theme}) => theme.text_a10};
        }
    
        .price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            b {
                font-size: ${({theme}) => theme.font_lg};
                font-weight: 600;
            }
    
            div:last-child {
                margin-left: auto;
            }
        }
    
        .floating {
            position: -webkit-sticky;
            position: sticky;
            bottom: 24px;
            flex-shrink: 0;
            margin-top: auto;
        }
    
        .included {
            color: ${({theme}) => theme.yumminn_blue};
            background: ${({theme}) => theme.yumminn_20};
            border-radius: ${({theme}) => theme.radius_xs};
            width: fit-content;
            padding: 0px 10px;
        }
    }

    &.menu {
        min-height: var(--dvh); /* fallback */
        min-height: 100dvh;
        border-radius: 0;

        & > header {
            color: ${({theme}) => theme.text_light};
            background-color: ${({theme}) => theme.header_bg};
            margin:  0;
            top: 0;
            padding: 8px;

            h2 {
                font-size: ${({theme}) => theme.font_md};
                font-weight: 600;
                line-height: 1.3;
                color: ${({theme}) => theme.text_dark};
                background-color: ${({theme}) => theme.tertiary_30};
                border-radius: ${({theme}) => theme.radius_round};
                padding: 8px 16px;
                margin: 0 auto 0 0;
            }

            button {
                color: inherit;
                background-color: transparent;
                padding: 4px 0 4px 8px;
                margin: 0;
                border: none;
                height: 100%;
                display: flex;
                align-items: center;

                &:last-child {
                    display: none;
                }
            }
        }

        & > h2 {
            position: initial;
        }
    }

    .alert {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        font-size: ${({theme}) => theme.font_md};
        color: ${({theme}) => theme.text_dark};
        background-color: ${({theme}) => theme.warning_40};
        border-radius: ${({theme}) => theme.radius_sm};
        padding: 8px 16px;

        > div {
            width: max-content;
        }
    }
`;

const ProductTitle = styled.h3<CardTextProps>`
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    line-height: 1.2rem;
    color: currentColor;
    white-space: ${({truncated}) => truncated ? 'nowrap' : ''};
    text-overflow: ${({truncated}) => truncated ? 'ellipsis' : ''};
    overflow: ${({truncated}) => truncated ? 'hidden' : ''};
    margin: ${({ hasDiscount }) => hasDiscount ? "15px 0 0 0" : "0"};
`;

const ProductPrice = styled.b<priceProps>`
    font-size: ${({theme}) => theme.font_md};
    font-weight: 700;
    line-height: 1;
    color: currentColor;
    display: flex;
    justify-content: ${({modifiers}) => modifiers ? 'flex-start' : 'flex-end'};
`;

const PriceWithoutDiscount = styled.span<{bigger?: boolean}>`
    font-size: ${({theme, bigger}) => bigger? theme.font_lg : theme.font_xs};
    line-height: 1;
    text-decoration: line-through;
    color: currentColor;
    display: flex;
`;

const PriceWithoutDiscountSwimlines = styled.div`
    font-size: ${({theme}) => theme.font_xxs};
    line-height: 1;
    text-decoration: line-through;
    color: currentColor;
    display: flex;
`;

const ProductWithModifierPrice = styled.div<priceProps>`
    font-size: ${({theme}) => theme.font_md};
    font-weight: 700;
    color: ${props => props.theme.text};
    display: flex;
    justify-content: ${props => props.modifiers ? 'flex-start' : 'flex-end'};
    background-color: ${props => props.theme.primary_10};
    border-radius: 18px;
    padding: 2px 15px;
    height: min-content;
    color: currentColor;
`;

type priceProps = {
    modifiers?: boolean
}

const Description = styled.p<CardTextProps>`
    font-size: ${({theme}) => theme.font_xs};
    line-height: 1.3;
    color: currentColor;
    opacity: 0.85;
    font-weight: 400;
    white-space: ${({truncated}) => truncated ? 'nowrap' : 'pre-wrap'};
    text-overflow: ${({truncated}) => truncated ? 'ellipsis' : ''};
    overflow: ${({truncated}) => truncated ? 'hidden' : ''};
    margin: 0;
    width: 100%;
`;

const DishOrder = styled.div<CardTextProps>`
    font-size: ${({theme}) => theme.font_xs};
    line-height: 1.3;
    color: ${props => props.theme.gray_80};
    font-weight: 700;
    white-space: ${({truncated}) => truncated ? 'nowrap' : ''};
    text-overflow: ${({truncated}) => truncated ? 'ellipsis' : ''};
    overflow: ${({truncated}) => truncated ? 'hidden' : ''};
    width: 100%;
`;

const ModifierContainer = styled(BaseCard).attrs({
    translate: "no",
})`
    display: flex;
    gap: 8px;
    padding: 10px;
    border-radius: ${({theme}) => theme.radius_sm};
    border-color: ${({selected}) => selected ? '' : 'transparent'};
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;
    
    img {
        height: 72px;
        width: 72px;
        object-fit: cover;
        border-radius: ${({theme}) => theme.radius_sm};
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: auto;
        width: 0;

        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            max-height: 20px;
        }

        h3 {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
                margin: -4px 0;
            }
        }
    }

    .out_of_stock {
        font-size: ${({theme}) => theme.font_xs};
        font-weight: 600;
        color: ${({theme}) => theme.danger};
        background-color: ${({theme}) => theme.danger_10};
        border-radius: ${({theme}) => theme.radius_xs};
        padding: 4px;
        display: inline-block;
        
        &::first-letter {
            text-transform: capitalize;
        }
    }
`;

const ModifiersDiv = styled.div`
    color: ${props => props.theme.text}; 
    font-size: 14px;
    font-weight: 400; 
`;

const ProductCounter = styled.div`
    color: ${props => props.theme.gray_80}; 
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    display: inline-block;
`;

const fadeIn = keyframes`
  from {
    background-color:rgba(229,112,112,.0);
    top: 0px
  }
  to {
    background-color:rgba(229,112,112,1);
    top: -10px;
  }
`;

type props = {
    animate: boolean
}

const ItemsBadge = styled.div<props>`
    position: absolute;
    top: -10px;
    right: -10px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 50%;
    background-color: #E57070;
    color: white;
    animation-fill-mode: backwards;
    animation: ${props =>
        props.animate &&
        css`
          ${fadeIn} 1s ease 0s 1 normal forwards;
        `};
    -webkit-animation: ${props =>
        props.animate &&
        css`
          ${fadeIn} 1s ease 0s 1 normal forwards;
        `};
`;

const TotalAmount = styled.div`
    color: ${props => props.theme.text}; 
    font-size: 16px;
    font-weight: 700; 
`;

const SymbolWrapper = styled.span<SymbolWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: ${props => !props.maxExtrasReached && props.disabled ? '0.5' : '1'};
    border: 2px solid  ${props =>  {
        if(props.symbol === 'plus' && props.maxExtrasReached){
            return props.theme.text_a80
        } 
        if((props.handleModifiers && props.symbol === 'checkmark') || (props.hasAmount && !props.maxExtrasReached )) {
            return props.theme.primary 
        }
        return props.theme.text
        
    }};
    border-radius: 50%;
    height: ${props => props.small ? '20px' : props.productWithModifiers ? '42px' : '24px'};
    width: ${props => props.small ? '20px' : props.productWithModifiers ? '42px' : '24px'};
    background: ${props =>  {
        if(props.symbol === 'plus' && props.maxExtrasReached){
            return props.theme.text_a80
        }
        if((props.handleModifiers && props.symbol === 'checkmark') || (props.hasAmount && !props.maxExtrasReached )) {
            return props.theme.primary 
        }
        return props.theme.text
    }};
    color: ${({theme}) => theme.background};
`;

const Symbol = styled.span<SymbolProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const CardModifiers = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 16px;
`;

const CardComment = styled.div`
    box-shadow: 0px 12px 12px 15px rgb(149 157 165 / 20%);
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgb(149 157 165 / 10%);
    border-radius: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 1px solid rgba(64,64,64,0.15);
`;

const ButtonContainerModifiers = styled.div`
    padding: 16px 24px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
`;

const CommentContainer = styled.div`
    border-top-width: 2px;
    padding: 20px 0;
`;

const PriceBadgeSmall = styled.div`
    background: ${props=>props.theme.yumminn_40};
    color: ${props=>props.theme.yumminn};
    width: min-content;
    height: min-content;
    padding: 0px 10px;
    border-radius: 4px;
`;

type SymbolWrapperProps = {
    symbol: string;
    productWithModifiers?: boolean;
    small?: boolean;
    handleModifiers?: boolean;
    onClick: React.MouseEventHandler;
    maxExtrasReached?: boolean;
    disabled?: boolean;
    hasAmount?: boolean;
}

type SymbolProps = {
    symbol: string;
    productWithModifiers?: boolean;
    handleModifiers?: boolean;
}

type CardContainerProps = {
    children: JSX.Element|JSX.Element[];   
    onClick?: () => void;
    style?: any;
    noPadding?: boolean;
}

type CardProps = {
    children: JSX.Element|JSX.Element[];
    onClick?: () => void;
    style?: any;
    id?: string;
    noPadding?: boolean;
}

const CardContainer = styled.div<CardContainerProps>`
    background: #F5F5F5;
    box-shadow: ${({theme}) => theme.shadow_xs};
    border-radius: 10px;
    border: 1px solid #E9E9E9;
    margin: -30px 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${props => props.noPadding ? '' : '10px 5px 5px 15px'};
`;

const CheckBoxWrapper = styled.span<SymbolWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: ${props => props.productWithModifiers ? '42px' : '27px'};
    width: ${props => props.productWithModifiers ? '42px' : '27px'};
    color: #FFF;
`;

const ModList = styled.ul<{receipt: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    padding: 0 0 0 16px;
    margin-top: 8px;

    &:not(:last-child) {
        margin-bottom: ${({receipt}) => receipt ? '0px' : '8px'};
    }

    h5 {
        font-size: ${({theme}) => theme.tinytFont};
        font-weight: 500;
        line-height: 1;
        margin: 0 -16px;
    }

    li {
        color: ${({theme}) => theme.text_a80};
        font-size: ${({theme}) => theme.font_sm};
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 2px 0;
    }

    span {
        margin: 0 0 0 auto;
    }
`;

const ModifiersCard = ({
    id,
    children,
    onClick,
    style,
    noPadding,
}: CardProps)=> {
    return (
        <CardContainer
        onClick={onClick}
        style={style}
        noPadding={noPadding}
        >
            {children}
        </CardContainer>
    )
};

type CardTextProps = {
    truncated?: boolean
    hasDiscount?: boolean
}

type CardDetailsContainerProps = {
    isExpanded?: boolean
}

export const AllergenWarningContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    font-size: ${({theme}) => theme.font_md};

    > div {
        width: max-content;
    }
`;

export {
    ReversedColumn,
    Card,
    MiniCard,
    DishOrder,
    ProductTitle,
    Description,
    ProductPrice,
    PriceWithoutDiscount,
    SymbolWrapper,
    Symbol,
    ModifierContainer,
    ModifiersDiv,
    ItemsBadge,
    CardModifiers,
    CardComment,
    TotalAmount,
    ProductCounter,
    CommentContainer,
    ButtonContainerModifiers,
    ModifiersCard,
    CheckBoxWrapper,
    ProductWithModifierPrice,
    PriceBadgeSmall,
    ModList,
    CardDetailsContainer,
    CardCounterContainer,
    CartCardInnerContainer,
    AddButtonsWrapper,
    InfoWrapper,
    PlusMinusIcon,
    PriceWithoutDiscountSwimlines
};