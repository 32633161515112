import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Icon, IconType } from '../icons';
import { InputContainer } from './InputContainers';

// ❗❗👀 Avoid unwanted line breaks in labels using this non-breaking space --> "  " <--
// Long labels should break in two lines max, don't use excessively long labels with this input variant

type InputProps = {
  name?: string
  value?: string
  defaultValue?: string
  label?: string | React.ReactNode
  title?: string | React.ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  password?: boolean
  error?: string | false
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onChange?: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
  onClick?: React.MouseEventHandler
  varForTranslation?: string
  readOnly?: boolean
};

export const TextInput = ({
  name,
  value,
  defaultValue,
  label,
  title,
  placeholder = ' ',
  disabled = false,
  required = false,
  password,
  error,
  leftIcon,
  rightIcon,
  onChange,
  onFocus,
  onBlur, 
  onClick,
  varForTranslation,
  readOnly 
}: InputProps) => {
  const [hidden, setHidden] = useState(password);

  const titleRef = useRef<HTMLHeadingElement>(null);

  const toggleVisibility: React.MouseEventHandler = (event) => {
    event.preventDefault();
    setHidden(prev => !prev);
  };

  const translateWithInterpolation = (key: string, interpolation?: { [key: string]: any }) => {
    return interpolation ? t(key, interpolation) : t(key);
  };

  useEffect(() => {
    const maxWidth = titleRef.current?.clientWidth ? `${titleRef.current.clientWidth}px` : 'auto';
    titleRef.current?.style.setProperty('--max_width', maxWidth);
  }, []);

  return (
    <InputContainer>
      {title &&
        <h5 className={required ? 'required' : ''}>
          {typeof title === 'string' ?  t(title) : title}
        </h5>
      }
      <label>
        {label &&
          <>
            <h6 ref={titleRef} className={(required && !title) ? 'required' : ''}>
              <span>
                {typeof label === 'string' ?  t(label) : label}
              </span>
            </h6>
            <hr/>
          </>
        }
        <div onClick={onClick}>
          {leftIcon}
          <input
            type={hidden ? 'password' : 'text'}
            id={name}
            name={name}
            value={value}
            defaultValue={defaultValue}
            placeholder={t(placeholder)}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete='off'
            readOnly={readOnly}
          />
          {error &&
            <div className='error'>
              <Icon type={IconType.Error}/>
            </div>
          }
          {password &&
            <button onClick={toggleVisibility} aria-label={hidden ? t('show_password') : t('hide_password')}>
              <Icon type={IconType[hidden ? 'Showpassword' : 'Hidepassword']} size={20}/>
            </button>
          }
          {rightIcon}
        </div>
      </label>
      {error &&
          <i>{typeof error === 'string' ? translateWithInterpolation(error, { varForTranslation }) : error}</i>
      }
    </InputContainer>
  );
};