import styled from "styled-components";

type props = {
  path?: string,
  ticketToolTipOpen?: boolean;
}

const DEMO_GROUP = '/restaurant_group/5c10ee0fd2a9411988ebc21bc333837f';

export const HeaderBarContainer = styled.header<props>`
  --header_bar_bg: ${({path, theme}) => path && path.includes('/restaurant_group') ? 'transparent': theme.header_bg};
  position: ${props => props.path == '/checkout' ? 'fixed' : 'sticky'};
  display: ${props => props.path == '/login' ? 'none' : 'flex'};
  justify-content: space-between;
  align-items: center;
  color: ${({path, theme}) => path && path.includes(DEMO_GROUP) ? theme.text_light : path && path.includes('/restaurant_group') ? theme.text : theme.text_light};
  background-color: var(--header_bar_bg);
  padding: 8px 16px;
  z-index:  ${({ticketToolTipOpen}) => ticketToolTipOpen? '9999' : '15'};
  width: fill-available;
`;

export const HeaderInnerContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  & > a {
    display: flex;
    align-items: center;
  }

  & > button {
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;
    color: currentColor;

    &:focus {
      outline: none;
    }
  }
`;

export const ProfileButton = styled.button`
  display: flex;
  color: currentColor;
  background-color: transparent;
  border: none;
  padding: 0;
`;

type PointsCounterProps = {
  type?: string;
}

export const PointsCounter = styled.div<PointsCounterProps>`
  background: ${({theme, type}) => type === 'credit' ? '#696969' : theme.header_counter_bg};
  border: 2px solid ${({theme, type}) => type === 'credit' ? '#848484' : theme.header_counter_border};
  box-shadow: ${({theme}) => theme.shadow_inset_sm};
  border-radius: ${({theme}) => theme.radius_round};
  display: flex;
  align-items: center;
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1em;
  height: 20px;
  padding: 2px 4px;

  span {
    margin: 0 4px;
    font-weight: 700;
  }
`;

export const InitialsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({theme}) => theme.font_sm};
  font-weight: 700;
  line-height: 1em;
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: ${({theme}) => theme.radius_round};
  color:  currentColor;
  border: 2px solid currentColor;
`;

export const ProfileHeader = styled.div<{showBackButton?: boolean}>`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({theme}) => theme.text};
  padding: 8px 16px;
  display: flex;
  min-height: 28px;
  z-index: 10;
  background-color: ${({theme}) =>  theme.header_bg};

  & > span {
    display: flex;
    text-align: center;
    align-items: center;
  }

  .back_button {
    opacity: ${({showBackButton}) => showBackButton ? '1' : '0'};
    pointer-events: ${({showBackButton}) => showBackButton ? 'all' : 'none'};
    color: ${({theme}) =>  theme.text_light};
  }
`;

export const ImgLogoBizum = styled.img`
  white-space: nowrap;
  width: 70px;
  left: 10px;
  margin-top: 3.5px;
  margin-left: 2px;
`;